<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-7 mt-5 mx-auto">
        <router-link to="/service-types" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Service type</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <label>Name</label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="serviceType.name"
              />
              <div class="mt-2" v-if="isUploading">
                <div class="card shadow-sm">
                  <div
                    class="card-body"
                    style="padding: 5px; padding-top: 10px"
                  >
                    <h5 class="card-title mb-4">Images Upload Progress</h5>

                    <div>
                      <div class="row mb-3">
                        <div class="col-md-4">
                          <p class="mb-1"><strong>Total Files:</strong></p>
                          <h2 class="mb-0">{{ totalFiles }}</h2>
                        </div>
                        <div class="col-md-4">
                          <p class="mb-1">
                            <strong>Successfully Uploaded:</strong>
                          </p>
                          <h2 class="mb-0 text-success">
                            {{ uploadedFilesCount }}
                          </h2>
                        </div>
                        <div class="col-md-4">
                          <p class="mb-1"><strong>Failed Attempts:</strong></p>
                          <h2 class="mb-0 text-danger">
                            {{ failedFilesCount }}
                          </h2>
                        </div>
                      </div>

                      <p v-if="currentFileIndex" class="mb-3">
                        Uploading File
                        <strong>{{ currentFileIndex }}</strong> of
                        <strong>{{ totalFiles }}</strong>
                      </p>

                      <div>
                        <p class="mb-2">
                          Upload Progress:
                          <strong>{{ imagesProgress }}%</strong>
                        </p>
                        <div class="progress" style="height: 25px">
                          <div
                            class="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            :style="{ width: `${imagesProgress}%` }"
                            :aria-valuenow="imagesProgress"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label>Upload Images</label>
                <input
                  class="form-control"
                  type="file"
                  ref="fileInput"
                  accept="image/*"
                  @change="handleFileUploadProjects"
                  multiple
                />
              </div>
              <div class="image-preview-container mb-2">
                <div
                  v-for="(image, index) in serviceType.images"
                  :key="index"
                  class="image-preview"
                >
                  <img
                    :src="image.image_url"
                    alt="Image Preview"
                    class="preview-image"
                  />
                  <button
                    class="delete-button"
                    @click="removeImage($event, index,image)"
                  >
                    ✕
                  </button>
                </div>
              </div>
              <label>Description</label>
              <QuillEditor
                v-model="serviceType.description"
                :editorHeight="'300px'"
                :editorWidth="'800px'"
              />
              <div class="mt-3 d-flex align-items-center">
                <label class="me-3">Status</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="registervolunteer"
                    v-model="serviceType.status"
                    :true-value="1"
                    :false-value="0"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <button
                    type="submit"
                    :disabled="isUploading"
                    class="btn btn-sm btn-primary"
                  >
                    {{
                      isUploading ? "Please wait, images uploading..." : "Save"
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";
import helpers from "@/helpers/helpers";

export default {
  components: {
    QuillEditor,
  },
  mixins: [helpers],
  data() {
    return {
      serviceType: {},
      serviceId: null,
      loading: false,
      modules: [],
      projectImages: [],
      imagesProgress: -1,
      isUploading: false,
      failedFilesCount: 0,
      uploadedFilesCount: 0,
      totalFiles: 0,
    };
  },
  mounted() {
    this.serviceId = this.$route.params.id;
    this.fetch(this.serviceId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("serviceTypes/getSingle", { id })
        .then((response) => {
          this.serviceType = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.error) {
            router.push({ path: "/service-types" });
            this.$toast.show("Page Not Found", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 2000,
            });
          }
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.serviceType);
      const id = this.serviceId;
      this.$store
        .dispatch("serviceTypes/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
        });
    },
    handleFileUploadProjects(event) {
      const files = event.target.files;
      const filesArr = Array.prototype.slice.call(files);
      filesArr.forEach((file) => {
        this.projectImages.push({
          file: file,
          image_url: URL.createObjectURL(file),
        });
      });
      this.submitFiles();
    },
    async submitFiles() {
      this.totalFiles = this.projectImages.length;
      this.uploadedFilesCount = 0;
      this.failedFilesCount = 0;
      this.isUploading = true;
      for (const [index, media] of this.projectImages.entries()) {
        this.currentFileIndex = index + 1;
        this.imagesProgress = 0;
        try {
          let filePath = await this.uploadFileInChunksInGallary(
            media.file,
            2,
            3,
            "Projects",
            (percentage) => {
              this.imagesProgress = percentage;
            }
          );
          if (filePath) {
            if (filePath === "failedFileUpload") {
              this.failedFilesCount++;
              continue;
            }
            this.serviceType.images.unshift({
              image_url: media.image_url,
              media: filePath,
            });
            this.uploadedFilesCount++;
          } else {
            this.failedFilesCount++;
            continue;
          }
        } catch (e) {
          this.failedFilesCount++;
        }
      }
      this.imagesProgress = 0;
      this.currentFileIndex = 0;
      this.isUploading = false;
      this.projectImages = [];
      this.$refs.fileInput.value = "";
    },
    removeImage(event, index, image) {
      event.preventDefault();
      this.serviceType.images.splice(index, 1);
      if (image.id) {
        const data = [image]; 
        this.$store
          .dispatch("gallary/deleteMedia", { data })
          .then((response) => {
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
    
<style scoped>
.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.image-preview {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.delete-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>