<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/news" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Press Release</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addTeam">
              <label>Title <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="news.title"
              />
              <!-- New input for description -->

              <label>Date & Time<span class="text-danger">*</span></label>
              <input
                type="datetime-local"
                class="form-control form-control-sm mb-3"
                v-model="news.date"
                required
              />

              <div class="d-flex align-items-center mt-3">
                <div class="mb-3 d-flex align-items-center">
                  <label class="me-3">Status</label>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="registervolunteer"
                      v-model="news.status"
                      :true-value="1"
                      :false-value="0"
                    />
                  </div>
                </div>
              </div>

              <label>Upload Image<span class="text-danger">*</span></label
              ><br />
              <input
                ref="fileInput"
                type="file"
                accept="image/*"
                class="form-control"
                @change="handleFileUpload"
              />

              <img
                v-if="mediaType === 'Image' || mediaType === 'image'"
                :src="imagePreview"
                alt="Image Preview"
                class="mt-2 w-100"
              />

              <video
                class="mt-2 w-100"
                id="video-preview"
                controls
                :src="imagePreview"
                v-if="mediaType == 'Video' || mediaType == 'video'"
              />

              <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgress != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgress + '%',
                        paddingLeft: Math.min(uploadProgress / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div>
                <label class="mt-2">PDF Document</label>
                <input
                  ref="fileInput"
                  @change="handlePdfUpload"
                  type="file"
                  accept="application/pdf"
                  class="form-control"
                />
              </div>

              <div v-if="pdfUrl" class="mt-2">
                <a
                  class="mt-2 link-with-underline"
                  :href="pdfUrl"
                  target="_blank"
                  >View PDF</a
                >
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgressPdf != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgressPdf + '%',
                        paddingLeft: Math.min(uploadProgressPdf / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgressPdf }}%
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <label class="mt-2">Detail</label>
              <QuillEditor
                v-model="news.detail"
                :editorHeight="'300px'"
                :editorWidth="'800px'"
              />

              <div class="row mt-3">
                <div class="col-md-12">
                  <button
                    type="submit"
                    :disabled="uploadProgress > 0 || uploadProgressPdf > 0"
                    class="btn btn-sm btn-primary"
                  >
                    {{
                      uploadProgress > 0 || uploadProgressPdf > 0
                        ? "Please wait, file uploading..."
                        : "Save"
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";
import helpers from "@/helpers/helpers";

export default {
  components: {
    QuillEditor,
  },
  mixins: [helpers],
  data() {
    return {
      news: {
        image: null,
      },
      newsId: null,
      loading: false,
      modules: [],
      imagePreview: null,
      mediaType: "",
      pdfUrl: "",
      uploadProgress: -1,
      uploadProgressPdf: -1,
    };
  },
  mounted() {
    this.newsId = this.$route.params.id;
    this.fetch(this.newsId);
  },
  methods: {
    async handleFileUpload(event) {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        this.mediaType = file.type.startsWith("image")
          ? "image"
          : file.type.startsWith("video")
          ? "video"
          : null;
        this.imagePreview = URL.createObjectURL(file);
        this.uploadProgress = 0.01;
        let filePath = await this.uploadFileInChunks(
          file,
          2,
          3,
          "Press",
          (percentage) => {
            this.uploadProgress = percentage;
          }
        );
        this.news.image = filePath;
        this.uploadProgress = -1;
      }
    },
    async handlePdfUpload(event) {
      const target = event.target;
      const file = target.files?.[0];

      const reader = new FileReader();

      reader.onload = (e) => {
        const blob = new Blob([e.target.result], { type: "application/pdf" });
        this.pdfUrl = URL.createObjectURL(blob);
        reader.readAsArrayBuffer(file);
      };
      reader.readAsArrayBuffer(file);
      if (file) {
        this.uploadProgressPdf = 0.01;
        let filePath = await this.uploadFileInChunks(
          file,
          2,
          3,
          "News",
          (percentage) => {
            this.uploadProgressPdf = percentage;
          }
        );
        this.news.document = filePath;
        this.uploadProgressPdf = -1;
      }
    },
    uploadPDF(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = btoa(
          new Uint8Array(reader.result).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        this.news.document = `data:application/pdf;base64,${base64String}`;
        const blob = new Blob([reader.result], { type: "application/pdf" });
        this.pdfUrl = URL.createObjectURL(blob);
      };
      reader.readAsArrayBuffer(file);
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("news/getSingle", { id })
        .then((response) => {
          this.news = response.data;
          this.pdfUrl =
            this.news.pdf && this.news.pdf.image_url
              ? this.news.pdf.image_url
              : null;
          this.mediaType = this.news.media.type;
          this.imagePreview = this.news.media.image_url;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.error) {
            router.push({ path: "/news" });
            this.$toast.show("Page Not Found", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 2000,
            });
          }
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.news);
      data.type = "Resolution";
      const id = this.newsId;
      this.$store
        .dispatch("news/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.uploadProgress = -1;
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
          this.uploadProgress = -1;
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("Failed to udpate", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
          this.uploadProgress = -1;
        });
    },
  },
};
</script>