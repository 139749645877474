<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/managment-team" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Member</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addTeam">
              <label>Name <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="team.full_name"
              />
              <label>Designation <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="team.desigantion"
              />
              <label>Image <span class="text-danger">*</span></label
              ><br />
              <input
                type="file"
                ref="fileInput"
                accept="image/*"
                class="form-control"
                @change="handleFileUpload"
                required
              />

              <!-- <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgress != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgress + '%',
                        paddingLeft: Math.min(uploadProgress / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </div>
              </div> -->

              <div class="col-md-6">
                <img
                  v-if="imagePreview"
                  :src="imagePreview"
                  alt="Image Preview"
                  class="mt-3 w-100"
                />
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgress != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgress + '%',
                        paddingLeft: Math.min(uploadProgress / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </div>
              </div>
              <!-- New input for description -->
              <div class="mt-3 d-flex align-items-center">
                <label class="me-3">Send Notification</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="sendNotificationYes"
                    v-model="notification"
                    :true-value="1"
                    :false-value="0"
                    @change="handleNotificationChange"
                  />
                </div>
              </div>
              <label class="mt-3">Bio</label>
              <textarea
                name=""
                id=""
                v-model="team.bio"
                class="form-control"
                cols="30"
                rows="10"
              ></textarea>
              <div class="row mt-3">
                <div class="col-md-12">
                  <button
                    type="submit"
                    :disabled="uploadProgress > 0"
                    class="btn btn-sm btn-primary"
                  >
                    {{
                      uploadProgress > 0
                        ? "Please wait, file uploading..."
                        : "Save"
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      id="openModel"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#notificationModal"
    >
      Open Modal
    </button>

    <notification_filter @save="filterResult"></notification_filter>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import notification from "@/store/modules/notification";
import notification_filter from "@/components/notification_filter.vue";
import helpers from "@/helpers/helpers";

export default {
  components: {
    QuillEditor,
    notification_filter,
  },
  mixins: [helpers],
  data() {
    return {
      files: [],
      team: {
        full_name: null,
        bio: null,
        image: null,
        desigantion: null,
        notificationFilter: [],
      },
      loading: false,
      modules: [],
      imagePreview: null,
      notification: 0,
      uploadProgress: -1,
    };
  },

  methods: {
    async handleFileUpload(event) {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        this.imagePreview = URL.createObjectURL(file);
        this.uploadProgress = 0.01;
        let filePath = await this.uploadFileInChunks(
          file,
          2,
          3,
          "Team",
          (percentage) => {
            this.uploadProgress = percentage;
          }
        );
        this.team.image = filePath;
        this.uploadProgress = -1;
      }
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.team);
      this.$store
        .dispatch("managmentTeam/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            Object.keys(this.team).forEach((key) => (this.team[key] = null));
            this.imagePreview = "";
            this.$refs.fileInput.value = "";
            this.notification = 0;
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.uploadProgress = -1;
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
            this.uploadProgress = -1;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("Failed to save", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
          this.uploadProgress = -1;
        });
    },
    handleNotificationChange() {
      if (this.notification == 1) {
        const btn = document.getElementById("openModel");
        btn.click();
      }
    },
    filterResult(result) {
      this.team.notificationFilter = result;
    },
  },
};
</script>