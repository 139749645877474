<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">

      </div>
      <div class="col-md-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h5 class="card-title mb-0">All Campaigns</h5>
            <router-link v-if="permissions && permissions.includes('campaign-Add')" to="/campaigns/add"
              class="btn btn-sm btn-primary">Add Campaign</router-link>
          </div>
          <div class="card-body">
            <data-table :columns="columns" :share="true" :module="module" :isCampaignUsers="isCampaignUsers" :items="items"
              :isEdit="isEdit" :isDelete="isDelete" @deleted="fetch" @nextPage="nextPage" @clear="fetch"
              @search="fetch"></data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Campaign Type",
          value: "type",
          secondValue: "name",
        },
        {
          text: "Register Volunteers",
          value: "is_volunters",
        },
        {
          text: "Start Date",
          value: "start_date",
        },
        {
          text: "End Date",
          value: "end_date",
        },

      ],
      module: "campaigns",
      permissions: null,
      isEdit: false,
      isDelete: false,
      isCampaignUsers: true,
      loading:false
    };
  },
  created() {
    this.fetch();
    this.permissions = localStorage.getItem("permissions");
    this.isEdit = (this.permissions && this.permissions.includes("campaign-Edit")) ? true : false;
    this.isDelete = (this.permissions && this.permissions.includes("campaign-Delete")) ? true : false;
  },
  methods: {
    fetch(search = "") {
      this.loading = true;
      this.$store
        .dispatch("campaigns/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url, search = "") {
      this.loading = true;
      const completeUrl = `${url}&paginate=true&search=${search}`;
      axiosInstance.get(completeUrl).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>