<template>
    <div class="container">
      <loading :active="loading" :is-full-page="true" loader="bars"></loading>
      <div class="row">
        <div class="col-md-8 mt-5 mx-auto">
          <router-link to="/user-service-request" class="btn btn-sm btn-primary mb-3"
            >Back</router-link
          >
         
           
            <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">Service Request Information</h5>
                    </div>
                    <div class="card-body">
                        <p><strong>Service Name:</strong> <span id="serviceType">{{ service.name }}</span></p>
                        <p><strong>Description:</strong> <span id="description">{{ service.detail }}</span></p>
                        <p><strong>Request Date:</strong> <span id="requestDate">{{ service.date }}</span></p>
                        <p><strong>Contribution:</strong> <span id="requestDate">{{ service.contribution }}</span></p>
                        <p v-if="service.contribution =='In Cash'"><strong>Amount:</strong> <span id="requestDate">{{ service.amount }}</span></p>
                       
                    </div>
                </div>
            </div>
            
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <h5 class="card-title">User Details</h5>
                    </div>
                    <div class="card-body">
                        <p><strong>Name:</strong> <span id="userName">{{ service?.user_profile?.name }}</span></p>
                        <p><strong>Email:</strong> <span id="userEmail">{{ service?.user?.email }}</span></p>
                        <p><strong>Cnic:</strong> <span id="userPhone">{{ service?.user?.cnic }}</span></p>
                        <p><strong>Phone:</strong> <span id="userPhone">{{ service?.user?.phone_number }}</span></p>
                        <p><strong>Address:</strong> <span id="userAddress">{{ service?.user_profile?.address }}</span></p>
                    </div>
                </div>
            </div>
    
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { toRaw } from "vue";
  import QuillEditor from "@/components/QuillEditor.vue";
  import router from "@/router";
  import helpers from "@/helpers/helpers";
  export default {
    components: {
      QuillEditor,
    },
    mixins: [helpers],
    data() {
      return {
        service:[],
        requestId: null,
        loading: false,
      };
    },
    mounted() {
      this.requestId = this.$route.params.id;
      this.fetch(this.requestId);
    },
    methods: {
    
      fetch(id) {
        this.loading = true;
        this.$store
          .dispatch("requestService/getSingle", { id })
          .then((response) => {
            this.service = response.data;
            console.log("projects", this.service);
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
          });
      },
     
    },
  };
  </script>
  
