<template>
  <li class="onhover-dropdown">
    <div class="notification-box">
      <div class="notification-icon">
        <svg>
          <use
            href="@/assets/svg/icon-sprite.svg#notification"
            @click="notification_open()"
          ></use>
        </svg>
      </div>
      <span class="badge rounded-pill">{{ notificationCount }}</span>
    </div>
    <div
      class="onhover-show-div notification-dropdown"
      :class="{ active: notification }"
    >
      <h6 class="mb-0 dropdown-title text-end" @click="readNotifications">
        Mark all as read
      </h6>
      <ul>
        <li
          class="b-l-primary border-4"
          @click="redirect(notification)"
          v-for="notification in notifications"
        >
          <p v-if="notification.body.title">
            {{ notification.body.title }}
            <span class="font-danger" v-if="notification.isRead == 0">New</span>
          </p>
          <p v-else>
            {{ JSON.parse(notification.body).title }}
            <span class="font-danger" v-if="notification.is_read == 0"
              >New</span
            >
          </p>

          <div class="row" v-if="notification.created_at">
            <small>{{ formatDate(notification.created_at) }}</small>
          </div>
          <div class="row" v-else>
            <small>just now</small>
          </div>

          <div v-if="notification.body.detail">
            <p>{{ notification.body.detail }}</p>
          </div>
          <div v-else>
            <p>{{ JSON.parse(notification.body).detail }}</p>
          </div>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "Notifications",
  data() {
    return {
      notification: false,
      uid: localStorage.getItem("uid"),
      notifications: [],
      notificationCount: 0,
      loading: false,
      channelName: null,
      pusherChannel: null,
    };
  },
  inject: ["pusherService"],
  mounted() {
    this.channelName = "admin." + localStorage.getItem("uid");
    this.pusher();
    this.fetchNotifications();
  },
  onUnmounted() {
    this.pusherService.unsubscribe(this.channelName);
  },
  methods: {
    notification_open() {
      this.notification = !this.notification;
    },
    pusher() {
      this.pusherChannel = this.pusherService.subscribe(this.channelName);
      this.pusherChannel.bind(this.channelName, (data) => {
        this.notifications.unshift(data);
        this.notificationCount += 1;

        if (!localStorage.getItem("notificationShown")) {
          localStorage.setItem("notificationShown", "true");
          this.showNotification(data);
          setTimeout(() => {
            localStorage.removeItem("notificationShown");
          }, 5000);
        }
      });
    },
    showNotification(data){
      if (Notification.permission === "granted") {
          this.showDesktopNotification(data);
        } else if (Notification.permission !== "denied") {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              this.showDesktopNotification(data);
            }
          });
        }
    },
    showDesktopNotification(notification) {
      const title = `ETMA : ${
        notification.body.title || JSON.parse(notification.body).title
      }`;
      const body =
        notification.body.detail || JSON.parse(notification.body).detail;
      new Notification(title, {
        body: body,
      });
    },
    fetchNotifications() {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("notification/getAll", { id })
        .then((response) => {
          this.notifications = response.data.notifications;
          this.notificationCount = response.data.count;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    readNotifications() {
      this.loading = true;
      const data = this.notifications;
      this.$store
        .dispatch("notification/update", { data })
        .then(() => {
          this.fetchNotifications();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    redirect(item) {
      let data;
      if (this.isJsonString(item.body)) {
        data = JSON.parse(item.body);
      } else {
        data = item.body;
      }
      const url = data.url;

      if (url) {
        this.$router.push(url);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
  },
};
</script>




<style scoped>
.badge {
  color: #eee;
  background: #222;
}
.notification-dropdown {
  max-height: 400px;
  width: 400px !important;
  overflow: auto !important;
  top: 50px !important;
}
</style>