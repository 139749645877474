<template>
  <div class="mt-5">
    <button @click="shareOnFacebook">Share on Facebook</button>

    
    <input type="file" ref="fileInput" @change="onFileChange" />
  </div>
</template>
    
    <script>
   import axiosInstance from "@/helpers/axios";
export default {
  data() {
    return {
      file: null, // To store the selected file
    };
  },
  methods: {
    // Method to handle file selection
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.file = file;
        console.log("File selected: ", file);
      }
    },
    initFacebookSDK() {
      // Dynamically load the Facebook SDK script
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);

        // Initialize the Facebook SDK after the script loads
        js.onload = () => {
          FB.init({
            appId: "599276539179135", // Replace with your Facebook App ID
            autoLogAppEvents: true,
            xfbml: true,
            version: "v17.0", // Use a valid version
          });

          // Log a message to the console after successful initialization
          console.log("Facebook SDK successfully initialized");
        };
      })(document, "script", "facebook-jssdk");
    },

    shareOnFacebook() {
      // Check if Facebook SDK is loaded
      if (typeof FB === "undefined") {
        console.error("Facebook SDK is not loaded");
        return;
      }

      // Prompt user to log in to Facebook
      FB.login(
        (response) => {
          if (response.authResponse) {
            FB.ui(
    {
      method: "share",
      href: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRNwNTt_Tk--CUqGFo0l6r0Jm71-BFhm1Hj_w&s", // Replace with the URL you want to share
      hashtag: "#YourHashtag", // Optional: Add a hashtag
      quote: "Check out this amazing content!", // Optional: Add a custom message
    },
    function (response) {
      if (response && !response.error_message) {
        console.log("Content shared successfully!");
      } else {
        console.error("Error while sharing content: ", response.error_message);
      }
    }
  );
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "pages_manage_posts" } // Request the required permissions
      );
    },
    redirectToOAuth() {
      // Construct the Facebook OAuth URL
      const appId = "599276539179135"; // Replace with your Facebook App ID
      const redirectUri = "https://tma.learnfirst.pk/auth/test"; // Replace with your redirect URI
      const state = "sdadasda"; // Optional: Use this to maintain state between requests
      const configId = "618848964006005"; // Replace with your Facebook App Config ID

      const oauthUrl = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${appId}&redirect_uri=${encodeURIComponent(
        redirectUri
      )}&state=${state}&config_id=${configId}`;

      window.location.href = oauthUrl;
    },

    uploadFileToBackend(formData) {
        axiosInstance
        .post("https://learnfirst.pk/sep6/backend/public/api/admin/facebook/share-post", formData)
      .then((response) => {
        console.log("File successfully uploaded to backend:", response.data);
      })
      .catch((error) => {
        console.error("Error uploading file to backend:", error);
      });
  },


  },
  mounted() {
    this.initFacebookSDK();
  },
};
</script>
    