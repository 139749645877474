<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <h1>User Activity</h1>
    <div class="activity-list">
      <div v-for="(activities, index) in items" :key="index">
        <h2 class="date-header">{{ formatDate(index) }}</h2>
        <div
          v-for="activity in activities"
          :key="activity.id"
          class="activity-item"
        >
          <div class="user-initial" v-if="!activity.profile_image">
            {{ capitalizeFirstLetter(activity.name) }}
          </div>
          <img
            v-else
            :src="activity.profile_image"
            alt="Profile Image"
            class="profile-image"
          />
          <div class="activity-content">
            <div class="activity-main">
              <span class="user-name">{{ activity.name }}</span>
              <span>{{ activity.action }}</span>
            </div>
            <!-- <div class="activity-details">{{ activity.details }}</div> -->
          </div>
          <div class="activity-meta">
            <span class="category">{{ activity.category }}</span>
            <span class="time">{{ activity.time }}</span>
            <!-- <svg
              class="view-icon"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
            >
              <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      loading: false,
      items: [],
    };
  },
  mounted() {
    this.fetch();
  },
 
  methods: {
    fetch() {
      this.loading = true;
      this.$store
        .dispatch("userActivity/getAll")
        .then((response) => {
          this.items = response.data;
          console.log("items", this.items);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    capitalizeFirstLetter(str) {
      if (typeof str !== "string" || str.length === 0) {
        return "";
      }
      return str.charAt(0).toUpperCase();
    },
    formatDate(dateString) {
      const [year, month, day] = dateString.split("-");
      const date = new Date(year, month - 1, day); 
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-GB", options).replace(",", "");
    },
  },
};
</script>
  
  <style scoped>
/* Existing CSS styles go here */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

body {
  background-color: #fafafa;
  color: #000;
  padding: 2rem;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.date-header {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 2rem 0 1rem 0;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid #eaeaea;
}

.activity-list {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.activity-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  padding-left: 1rem;
  border-bottom: 1px solid #f3f3f3;
  transition: background-color 0.2s;
}

.activity-item:hover {
  background-color: #f9f9f9;
}

.activity-item:last-child {
  border-bottom: none;
}

.user-initial {
  width: 36px;
  height: 36px;
  background: #f3f3f3;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-right: 1rem;
  flex-shrink: 0;
}

.profile-image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
  flex-shrink: 0;
}

.activity-content {
  flex-grow: 1;
}

.activity-main {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.user-name {
  font-weight: 500;
}

.activity-details {
  color: #666;
  font-size: 0.875rem;
}

.activity-meta {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
}

.category {
  background: #f3f3f3;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  color: #666;
}

.time {
  color: #666;
  font-size: 0.875rem;
}

.view-icon {
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
}

.view-icon:hover {
  opacity: 0.7;
}
</style>
  