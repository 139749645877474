<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/about-tma-videos" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Video</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit">
              <label for="title"
                >Title <span class="text-danger">*</span></label
              >
              <input
                type="text"
                id="title"
                class="form-control form-control-sm mb-3"
                placeholder="Enter title"
                required
                v-model="aboutTma.title"
              />

              <label>Upload Option <span class="text-danger">*</span></label>

              <div class="d-flex flex-row">
                <div class="form-check me-2">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="uploadFile"
                    value="file"
                    v-model="uploadOption"
                  />
                  <label class="form-check-label" for="uploadFile">
                    Upload Video File
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="uploadLink"
                    value="link"
                    v-model="uploadOption"
                  />
                  <label class="form-check-label" for="uploadLink">
                    Provide Video Link
                  </label>
                </div>
              </div>
              <div v-if="uploadOption === 'file'">
                <label>Video<span class="text-danger">*</span></label>
                <input
                  ref="video"
                  type="file"
                  accept="video/*"
                  class="mb-4 form-control"
                  @change="handleFileUpload"
                  required
                />
                <video
                  class="mt-2 mb-2 w-100"
                  id="video-preview"
                  controls
                  :src="imagePreview"
                  v-if="mediaType == 'video'"
                />
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgress != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgress + '%',
                        paddingLeft: Math.min(uploadProgress / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="uploadOption === 'link'">
                <label for="videoLink"
                  >Video Link <span class="text-danger">*</span></label
                >
                <input
                  type="url"
                  id="videoLink"
                  class="form-control form-control-sm mb-3"
                  placeholder="Enter video link"
                  required
                  v-model="aboutTma.video_link"
                  @input="handleVideoLinkInput"
                />
              </div>

              <div class="d-flex align-items-center mt-3">
                <div class="mb-3 d-flex align-items-center">
                  <label class="me-3">Status</label>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="registervolunteer"
                      v-model="aboutTma.status"
                      :true-value="1"
                      :false-value="0"
                    />
                  </div>
                </div>
                <div class="mb-3 d-flex align-items-center ms-4">
                  <label class="me-3">Send Notification</label>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="sendNotificationYes"
                      v-model="aboutTma.notification"
                      :true-value="1"
                      :false-value="0"
                      @change="handleNotificationChange"
                    />
                  </div>
                </div>
              </div>

              <label for="shortDetail">Short Detail</label>
              <QuillEditor
                v-model="aboutTma.detail"
                :editorHeight="'300px'"
                :editorWidth="'800px'"
              />
              <div class="row mt-3">
                <div class="col-md-12">
                  <button
                    type="submit"
                    :disabled="uploadProgress > 0"
                    class="btn btn-sm btn-primary"
                  >
                    {{
                      uploadProgress > 0
                        ? "Please wait, file uploading..."
                        : "Save"
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <button
      type="button"
      id="openModel"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#notificationModal"
    >
      Open Modal
    </button>

    <notification_filter @save="filterResult"></notification_filter>
  </div>
</template>

<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import notification_filter from "@/components/notification_filter.vue";
import helpers from "@/helpers/helpers";

export default {
  components: {
    QuillEditor,
    notification_filter,
  },
  mixins: [helpers],
  data() {
    return {
      files: [],
      aboutTma: {
        video_file: null,
        video_link: null,
        status: 1,
        notification: 0,
        detail: "",
        notificationFilter: [],
      },
      loading: false,
      modules: [],
      mediaType: "",
      imagePreview: null,
      uploadOption: "file",
      uploadProgress: -1,
    };
  },

  methods: {
    async handleFileUpload(event) {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        this.mediaType = file.type.startsWith("image")
          ? "image"
          : file.type.startsWith("video")
          ? "video"
          : null;
        this.imagePreview = URL.createObjectURL(file);
        this.uploadProgress = 0.01;
        let filePath = await this.uploadFileInChunks(
          file,
          2,
          3,
          "aboutTmaVideos",
          (percentage) => {
            this.uploadProgress = percentage;
          }
        );
        this.aboutTma.video_file = filePath;
        this.uploadProgress = -1;
      }
    },
    handleVideoLinkInput(event) {
      this.aboutTma.video_link = event.target.value;
    },
    isValidVideoLink(url) {
      return url && (url.startsWith("http://") || url.startsWith("https://"));
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.aboutTma);
      data.type = this.uploadOption;
      if (this.uploadOption === "link") {
        delete data.video_file;
      } else {
        delete data.video_link;
      }
      this.$store
        .dispatch("aboutTmaVideos/store", {
          data,
        })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfully added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });

            Object.keys(this.aboutTma).forEach(
              (key) => (this.aboutTma[key] = null)
            );
            this.aboutTma.status = 1;
            this.aboutTma.notification = 0;
            this.$refs.video.value = "";
            this.mediaType = "";
            this.uploadOption = "file";
            this.uploadProgress = -1;
            this.imagePreview = null;
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    handleNotificationChange() {
      if (this.aboutTma.notification == 1) {
        const btn = document.getElementById("openModel");
        btn.click();
      }
    },
    filterResult(result) {
      this.aboutTma.notificationFilter = result;
    },
    onUploadProgress(progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
  },
};
</script>
