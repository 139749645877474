<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-7 mt-5 mx-auto">
        <router-link to="/projects" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edit Project</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addProject">
              <label>Title <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.project_name"
              />
              <label>Location<span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.location"
              />
              <label>Start Date <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.start_date"
              />
              <label>End Date <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.end_date"
              />
              <label>Status <span class="text-danger">*</span></label>
              <div class="mb-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="statusOngoing"
                    name="status"
                    value="Ongoing"
                    v-model="project.status"
                    required
                  />
                  <label class="form-check-label" for="statusOngoing"
                    >Ongoing</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="statusCompleted"
                    name="status"
                    value="Completed"
                    v-model="project.status"
                    required
                  />
                  <label class="form-check-label" for="statusCompleted"
                    >Completed</label
                  >
                </div>
              </div>
              <label>Reference to<span class="text-danger">*</span></label>
              <div class="mb-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="ddcRadio"
                    name="radioStatus"
                    v-model="project.refTo"
                    value="DDC"
                    required
                  />
                  <label class="form-check-label" for="ddcRadio">DDC</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="tdcRadio"
                    name="radioStatus"
                    v-model="project.refTo"
                    value="TDC"
                    required
                  />
                  <label class="form-check-label" for="tdcRadio">TDC</label>
                </div>
              </div>
              <div class="mb-3">
                <label
                  >Upload Cover Image/Video<span class="text-danger"
                    >*</span
                  ></label
                >
                <input
                  type="file"
                  ref="video"
                  accept="image/*,video/*"
                  class="form-control"
                  @change="handleFileUpload"
                />
              </div>
              <img
                v-if="mediaType == 'Image' || mediaType == 'image'"
                :src="imagePreview"
                alt="Image Preview"
                class="mt-2 w-100 mb-2"
              />
              <video
                class="mt-2 mb-2 w-100"
                id="video-preview"
                controls
                :src="imagePreview"
                v-if="mediaType == 'Video' || mediaType == 'video'"
              />
              <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgress != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgress + '%',
                        paddingLeft: Math.min(uploadProgress / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </div>
              </div>
              <a href="#" @click.prevent="openFileInput">
                Add Project Images
              </a>
              <input
                type="file"
                ref="fileInput"
                accept="image/*"
                style="display: none"
                @change="handleFileUploadProjects"
                multiple
              />
              <div class="mt-2" v-if="isUploading">
                <div class="card shadow-sm">
                  <div
                    class="card-body"
                    style="padding: 5px; padding-top: 10px"
                  >
                    <h5 class="card-title mb-4">Images Upload Progress</h5>

                    <div>
                      <div class="row mb-3">
                        <div class="col-md-4">
                          <p class="mb-1"><strong>Total Files:</strong></p>
                          <h2 class="mb-0">{{ totalFiles }}</h2>
                        </div>
                        <div class="col-md-4">
                          <p class="mb-1">
                            <strong>Successfully Uploaded:</strong>
                          </p>
                          <h2 class="mb-0 text-success">
                            {{ uploadedFilesCount }}
                          </h2>
                        </div>
                        <div class="col-md-4">
                          <p class="mb-1"><strong>Failed Attempts:</strong></p>
                          <h2 class="mb-0 text-danger">
                            {{ failedFilesCount }}
                          </h2>
                        </div>
                      </div>

                      <p v-if="currentFileIndex" class="mb-3">
                        Uploading File
                        <strong>{{ currentFileIndex }}</strong> of
                        <strong>{{ totalFiles }}</strong>
                      </p>

                      <div>
                        <p class="mb-2">
                          Upload Progress:
                          <strong>{{ imagesProgress }}%</strong>
                        </p>
                        <div class="progress" style="height: 25px">
                          <div
                            class="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            :style="{ width: `${imagesProgress}%` }"
                            :aria-valuenow="imagesProgress"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="image-preview-container">
                <div
                  v-for="(image, index) in project.images"
                  :key="index"
                  class="image-preview"
                >
                  <img
                    :src="image.image_url"
                    alt="Image Preview"
                    class="preview-image"
                  />
                  <button
                    class="delete-button"
                    @click="removeImage($event, index, image)"
                  >
                    ✕
                  </button>
                </div>
              </div>
              <br />
              <label>Description</label>
              <QuillEditor
                v-model="project.description"
                :editorHeight="'300px'"
                :editorWidth="'800px'"
              />
              <div class="row mt-3">
                <div class="col-md-12">
                  <button
                    type="submit"
                    :disabled="uploadProgress > 0 || isUploading"
                    class="btn btn-sm btn-primary"
                  >
                    {{
                       uploadProgress > 0 || isUploading
                        ? "Please wait, file uploading..."
                        : "Save"
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";
import helpers from "@/helpers/helpers";
export default {
  components: {
    QuillEditor,
  },
  mixins: [helpers],
  data() {
    return {
      project: {
        image: null,
        images: [],
        status: "",
      },
      roleId: null,
      loading: false,
      modules: [],
      mediaType: "",
      imagePreview: null,
      uploadProgress: -1,
      projectImages: [],
      imagesProgress: -1,
      isUploading: false,
      failedFilesCount: 0,
      uploadedFilesCount: 0,
      totalFiles: 0,
    };
  },
  mounted() {
    this.roleId = this.$route.params.id;
    this.fetch(this.roleId);
  },
  methods: {
    async handleFileUpload(event) {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        this.mediaType = file.type.startsWith("image")
          ? "image"
          : file.type.startsWith("video")
          ? "video"
          : null;
        this.imagePreview = URL.createObjectURL(file);
        this.uploadProgress = 0.01;
        let filePath = await this.uploadFileInChunks(
          file,
          2,
          3,
          "Projects",
          (percentage) => {
            this.uploadProgress = percentage;
          }
        );
        this.project.image = filePath;
        this.uploadProgress = -1;
      }
    },
    handleFileUploadProjects(event) {
      const files = event.target.files;
      const filesArr = Array.prototype.slice.call(files);
      filesArr.forEach((file) => {
        this.projectImages.push({
          file: file,
          image_url: URL.createObjectURL(file),
        });
      });
      this.submitFiles();
    },
    async submitFiles() {
      this.totalFiles = this.projectImages.length;
      this.uploadedFilesCount = 0;
      this.failedFilesCount = 0;
      this.isUploading = true;
      for (const [index, media] of this.projectImages.entries()) {
        this.currentFileIndex = index + 1;
        this.imagesProgress = 0.01;
        try {
          let filePath = await this.uploadFileInChunksInGallary(
            media.file,
            2,
            3,
            "Projects",
            (percentage) => {
              this.imagesProgress = percentage;
            }
          );
          if (filePath) {
            if (filePath === "failedFileUpload") {
              this.failedFilesCount++;
              continue;
            }
            this.project.images.unshift({
              image_url: media.image_url,
              media: filePath,
            });
            this.uploadedFilesCount++;
          } else {
            this.failedFilesCount++;
            continue;
          }
        } catch (e) {
          this.failedFilesCount++;
        }
      }
      this.imagesProgress = 0;
      this.currentFileIndex = 0;
      this.isUploading = false;
      this.projectImages = [];
    },
    handleMultipleImageUpload(event) {
      const files = event.target.files;
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.project.images.push(e.target.result);
          };
          reader.readAsDataURL(files[i]);
        }
      }
    },
    removeImage(event, index, image) {
      event.preventDefault();
      this.project.images.splice(index, 1);
      if (image.id) {
        const data = [image];
        this.$store
          .dispatch("gallary/deleteMedia", { data })
          .then((response) => {
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
          });
      }
    },
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("projects/getSingle", { id })
        .then((response) => {
          this.project = response.data;
          this.mediaType = this.project.media.type;
          this.imagePreview = this.project.media.image_url;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.error) {
            router.push({ path: "/projects" });
            this.$toast.show("Page Not Found", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 2000,
            });
          }
        });
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.project);
      const id = this.roleId;
      this.$store
        .dispatch("projects/update", { data, id })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly updated", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.uploadProgress = -1;
          } else {
            this.$toast.show("Failed to udpate", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch((e) => {
          this.loading = false;
          this.uploadProgress = -1;
        });
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
  },
};
</script>


<style scoped>
.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.image-preview {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.delete-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>