<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-7 mt-5 mx-auto">
        <router-link to="/projects" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Project</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addProject">
              <label>Title <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.project_name"
              />
              <label>Location<span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.location"
              />
              <label>Start Date <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.start_date"
              />
              <label>End Date <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control form-control-sm mb-3"
                required
                v-model="project.end_date"
              />
              <label>Status <span class="text-danger">*</span></label>
              <div class="mb-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="statusOngoing"
                    name="status"
                    value="Ongoing"
                    v-model="project.status"
                    required
                  />
                  <label class="form-check-label" for="statusOngoing"
                    >Ongoing</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="statusCompleted"
                    name="status"
                    value="Completed"
                    v-model="project.status"
                    required
                  />
                  <label class="form-check-label" for="statusCompleted"
                    >Completed</label
                  >
                </div>
              </div>
              <div class="mb-3 d-flex align-items-center">
                <label class="me-3 mb-2">Send Notification</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="sendNotificationYes"
                    @change="handleNotificationChange"
                    v-model="project.notification"
                    :true-value="1"
                    :false-value="0"
                  />
                </div>
              </div>
              <label>Reference to<span class="text-danger">*</span></label>
              <div class="mb-3">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="ddcRadio"
                    name="radioStatus"
                    v-model="project.refTo"
                    value="DDC"
                    required
                  />
                  <label class="form-check-label" for="ddcRadio">DDC</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="tdcRadio"
                    name="radioStatus"
                    v-model="project.refTo"
                    value="TDC"
                    required
                  />
                  <label class="form-check-label" for="tdcRadio">TDC</label>
                </div>
              </div>
              <div class="mb-3">
                <label
                  >Upload Cover Image<span class="text-danger">*</span></label
                >
                <input
                  type="file"
                  ref="video"
                  accept="image/*"
                  class="form-control"
                  @change="handleFileUpload"
                  required
                />
              </div>
              <img
                v-if="mediaType === 'image'"
                :src="imagePreview"
                alt="Image Preview"
                class="mt-2 w-100 mb-2"
              />
              <video
                class="mt-2 mb-2 w-100"
                id="video-preview"
                controls
                :src="imagePreview"
                v-if="mediaType == 'video'"
              />
              <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgress != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgress + '%',
                        paddingLeft: Math.min(uploadProgress / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <a href="#" @click.prevent="openFileInput">
                Add Project Images
              </a>
              <input
                type="file"
                ref="fileInput"
                accept="image/*"
                style="display: none"
                @change="handleFileUploadProjects"
                multiple
              />
              <div class="mt-2" v-if="isUploading">
                <div class="card shadow-sm">
                  <div
                    class="card-body"
                    style="padding: 5px; padding-top: 10px"
                  >
                    <h5 class="card-title mb-4">Images Upload Progress</h5>

                    <div>
                      <div class="row mb-3">
                        <div class="col-md-4">
                          <p class="mb-1"><strong>Total Files:</strong></p>
                          <h2 class="mb-0">{{ totalFiles }}</h2>
                        </div>
                        <div class="col-md-4">
                          <p class="mb-1">
                            <strong>Successfully Uploaded:</strong>
                          </p>
                          <h2 class="mb-0 text-success">
                            {{ uploadedFilesCount }}
                          </h2>
                        </div>
                        <div class="col-md-4">
                          <p class="mb-1"><strong>Failed Attempts:</strong></p>
                          <h2 class="mb-0 text-danger">
                            {{ failedFilesCount }}
                          </h2>
                        </div>
                      </div>

                      <p v-if="currentFileIndex" class="mb-3">
                        Uploading File
                        <strong>{{ currentFileIndex }}</strong> of
                        <strong>{{ totalFiles }}</strong>
                      </p>

                      <div>
                        <p class="mb-2">
                          Upload Progress:
                          <strong>{{ imagesProgress }}%</strong>
                        </p>
                        <div class="progress" style="height: 25px">
                          <div
                            class="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            :style="{ width: `${imagesProgress}%` }"
                            :aria-valuenow="imagesProgress"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="image-preview-container">
                <div
                  v-for="(image, index) in project.images"
                  :key="index"
                  class="image-preview"
                >
                  <img
                    :src="image.image_url"
                    alt="Image Preview"
                    class="preview-image"
                  />
                  <button
                    class="delete-button"
                    @click="removeImage($event, index)"
                  >
                    ✕
                  </button>
                </div>
              </div>
              <label>Description</label>
              <QuillEditor
                v-model="project.description"
                :editorHeight="'300px'"
                :editorWidth="'800px'"
              />
              <div class="row mt-3">
                <div class="col-md-12">
                  <button
                    type="submit"
                    :disabled="uploadProgress > 0 || isUploading"
                    class="btn btn-sm btn-primary"
                  >
                    {{
                      uploadProgress > 0 || isUploading
                        ? "Please wait, file uploading..."
                        : "Save"
                    }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      id="openModel"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#notificationModal"
    >
      Open Modal
    </button>

    <notification_filter @save="filterResult"></notification_filter>
  </div>
</template>
<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import notification_filter from "@/components/notification_filter.vue";
import helpers from "@/helpers/helpers";

export default {
  components: {
    QuillEditor,
    notification_filter,
  },
  mixins: [helpers],
  data() {
    return {
      files: [],
      project: {
        image: null,
        images: [],
        status: "",
        notification: 0,
        refTo: "",
        notificationFilter: [],
      },
      loading: false,
      modules: [],
      mediaType: "",
      imagePreview: "",
      uploadProgress: -1,
      projectImages: [],
      imagesProgress: -1,
      isUploading: false,
      failedFilesCount: 0,
      uploadedFilesCount: 0,
      totalFiles: 0,
    };
  },

  methods: {
    async handleFileUpload(event) {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        this.mediaType = file.type.startsWith("image")
          ? "image"
          : file.type.startsWith("video")
          ? "video"
          : null;
        this.imagePreview = URL.createObjectURL(file);
        this.uploadProgress = 0.01;
        let filePath = await this.uploadFileInChunks(
          file,
          2,
          3,
          "Projects",
          (percentage) => {
            this.uploadProgress = percentage;
          }
        );
        this.project.image = filePath;
        this.uploadProgress = -1;
      }
    },
    handleFileUploadProjects(event) {
      const files = event.target.files;
      const filesArr = Array.prototype.slice.call(files);
      filesArr.forEach((file) => {
        this.projectImages.push({
          file: file,
          image_url: URL.createObjectURL(file),
        });
      });
      this.submitFiles();
    },
    async submitFiles() {
      this.totalFiles = this.projectImages.length;
      this.uploadedFilesCount = 0;
      this.failedFilesCount = 0;
      this.isUploading = true;
      for (const [index, media] of this.projectImages.entries()) {
        this.currentFileIndex = index + 1;
        this.imagesProgress = 0;
        try {
          let filePath = await this.uploadFileInChunksInGallary(
            media.file,
            2,
            3,
            "Projects",
            (percentage) => {
              this.imagesProgress = percentage;
            }
          );
          if (filePath) {
            if (filePath === "failedFileUpload") {
              this.failedFilesCount++;
              continue;
            }
            this.project.images.unshift({
              image_url: media.image_url,
              media: filePath,
            });
            this.uploadedFilesCount++;
          } else {
            this.failedFilesCount++;
            continue;
          }
        } catch (e) {
          this.failedFilesCount++;
        }
      }
      this.imagesProgress = 0;
      this.currentFileIndex = 0;
      this.isUploading = false;
      this.projectImages = [];
    },
    removeImage(event, index) {
      event.preventDefault();
      this.project.images.splice(index, 1);
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.project);
      this.$store
        .dispatch("projects/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.project).forEach(
              (key) => (this.project[key] = null)
            );
            this.$refs.video.value = "";
            this.project.notification = 0;
            this.project.images = [];
            this.projectImages = [];
            this.mediaType = "";
            this.uploadProgress = -1;
            this.$refs.fileInput.value = "";
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
            this.uploadProgress = -1;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show(e.error, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
          this.uploadProgress = -1;
        });
    },
    handleNotificationChange() {
      if (this.project.notification == 1) {
        const btn = document.getElementById("openModel");
        btn.click();
      }
    },
    filterResult(result) {
      this.project.notificationFilter = result;
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
  },
};
</script>


<style scoped>
.image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.image-preview {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.delete-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>