<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-8 mt-5 mx-auto">
        <router-link
          to="/user-service-complaints"
          class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >

        <div class="row mt-4">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">Complaint Information</h5>
              </div>
              <div class="card-body">
                <p>
                  <strong>Service Name:</strong>
                  <span id="serviceType">{{ service?.service?.name }}</span>
                </p>
                <p>
                  <strong>Title:</strong>
                  <span id="description">{{ service?.title }}</span>
                </p>
                <p>
                  <strong>Description:</strong>
                  <span id="description">{{ service?.description }}</span>
                </p>
                <p>
                  <strong>Date:</strong>
                  <span id="requestDate">{{ service?.date }}</span>
                </p>
                <p>
                  <strong class="me-2">Status:</strong>
                  <select
                    id="statusSelect"
                    v-model="service.status"
                    @change="handleStatusChange"
                    class="form-select form-select-sm d-inline-block w-auto"
                  >
                    <option value="Pending">Pending</option>
                    <option value="Resolved">Resolved</option>
                  </select>
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">User Details</h5>
              </div>
              <div class="card-body">
                <p>
                  <strong>Name:</strong>
                  <span id="userName">{{ service?.user_profile?.name }}</span>
                </p>
                <p>
                  <strong>Email:</strong>
                  <span id="userEmail">{{ service?.user?.email }}</span>
                </p>
                <p>
                  <strong>Cnic:</strong>
                  <span id="userPhone">{{ service?.user?.cnic }}</span>
                </p>
                <p>
                  <strong>Phone:</strong>
                  <span id="userPhone">{{ service?.user?.phone_number }}</span>
                </p>
                <p>
                  <strong>Address:</strong>
                  <span id="userAddress">{{
                    service?.user_profile?.address
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import router from "@/router";
import helpers from "@/helpers/helpers";
export default {
  components: {
    QuillEditor,
  },
  mixins: [helpers],
  data() {
    return {
      service: [],
      requestId: null,
      loading: false,
    };
  },
  mounted() {
    this.requestId = this.$route.params.id;
    this.fetch(this.requestId);
  },
  methods: {
    fetch(id) {
      this.loading = true;
      this.$store
        .dispatch("requestComplaints/getSingle", { id })
        .then((response) => {
          this.service = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    handleStatusChange() {
      this.loading = true;
      const id = this.requestId;
      this.$store
        .dispatch("requestComplaints/updateStatus", { id })
        .then((response) => {
          this.$toast.show("Successfuly Updated Status", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
  },
};
</script>
  
