<template>
    <div
      v-if="visible"
      class="fixed top-0 left-0 w-100 h-100 bg-black bg-opacity-50 d-flex align-items-center justify-content-center z-index-1050"
    >
      <div class="bg-white rounded-lg p-4">
        <h2 class="h5 font-weight-semibold text-dark mb-3">Share this {{ title }}</h2>
        <div class="d-flex flex-column space-y-3">
          <!-- ShareOn Buttons -->
          <div class="shareon" :data-url="fullUrl" :data-title="title">
            <a class="btn btn-primary facebook">Facebook</a>
            <a class="btn btn-info twitter">Twitter</a>
            <a class="btn btn-primary linkedin">LinkedIn</a>
            <a class="btn btn-success whatsapp">WhatsApp</a>
          </div>
        </div>
        <button class="mt-3 text-muted hover:text-dark" @click="$emit('close')">Cancel</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
      url: {
        type: String,
        default: '',
      },
      image: {
        type: String,
        default: '',
      },
      page: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        shareUrl: null,
      };
    },
  
    watch: {
      visible(newValue) {
        if (newValue) {
          this.initShareon();
        }
      },
    },


    computed: {
    fullUrl() {
      const baseUrl = process.env.VUE_WEB_URL; 
      return `${baseUrl}${this.url}`; 
    },
  },
    
    methods: {
      initShareon() {
        this.$nextTick(() => {
          Shareon.init();
        });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Modal styling */
  .fixed {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .bg-white {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Share buttons */
  a {
    display: inline-block;
    margin: 5px;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    text-decoration: none;
  }
  
  .facebook {
    background-color: #4267b2 !important;
  }
  
  .twitter {
    background-color: #1da1f2 !important;
  }
  
  .linkedin {
    background-color: #0077b5 !important;
  }
  
  .whatsapp {
    background-color: #25d366 !important;
  }
  
  /* Ensure buttons are styled but non-clickable */
  .not-clickable {
    opacity: 0.8;
    cursor: not-allowed;
  }
  
  /* Cancel button */
  button {
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    text-decoration: underline;
  }
  </style>
  