<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- page-wrapper Start-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <!-- <a class="logo">
                  <img
                    class="img-fluid for-light"
                    src="../assets/images/logo/logo.png"
                    alt="looginpage"
                  />
                  <img
                    class="img-fluid for-dark"
                    src="../assets/images/logo/logo_dark.png"
                    alt="looginpage"
                  />
                </a> -->
              </div>
              <div class="login-main">
                <form class="theme-form" @submit.prevent="login">
                  <h4>Sign in to account</h4>
                  <p>Enter your email & password to login</p>
                  <span class="text-danger" v-if="errors.login">{{
                    errors.login
                  }}</span>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input
                      class="form-control form-control-sm"
                      type="email"
                      name="email"
                      placeholder="email@test.com"
                      v-model="user.email"
                      required
                      autofocus
                    />
                    <span class="text-danger" v-if="errors.email">{{
                      errors.email[0]
                    }}</span>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input
                        class="form-control form-control-sm"
                        type="password"
                        placeholder="*********"
                        v-model="user.password"
                        required
                      />
                      <span class="text-danger" v-if="errors.password">{{
                        errors.password[0]
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input
                        id="checkbox1"
                        type="checkbox"
                        value="true"
                        v-model="user.remember_me"
                      />
                      <label class="text-muted" for="checkbox1"
                        >Remember me</label
                      >
                    </div>
                    <router-link class="link" to="/auth/verifyEmail"
                      >Forgot password?</router-link
                    >
                    <div class="text-end mt-3">
                      <button
                        class="btn btn-sm btn-primary btn-block w-100"
                        type="submit"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
</template>

<script>
import { toRaw } from "vue";
import router from "@/router";

export default {
  name: "login",
  data() {
    return {
      user: {
        email: "",
        password: "",
        remember_me: false,
      },
      errors: [],
      loading: false,
      permissions: [],
    };
  },
  created() {
    if (!!localStorage.getItem("token")) {
      router.push({ path: "/" });
    }
  },
  methods: {
    login() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.user);
      this.$store
        .dispatch("auth/login", { data })
        .then((response) => {
          if (response.success == true) {
            response.data.roles[0].permissions.forEach((item) => {
              this.permissions.push(item.name);
            });
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("uid", response.data.id);
            localStorage.setItem("f_name", response.data.f_name);
            localStorage.setItem("permissions", toRaw(this.permissions));
            localStorage.setItem(
              "remember_me",
              response.data.remember_me_token
            );
            localStorage.setItem(
              "tmaId",
              response.data.parent_id
                ? response.data.parent_id
                : response.data.id
            );
            localStorage.setItem("tmaName", response.data.tma);
            localStorage.setItem(
              "role",
              response?.data?.roles?.[0]?.name ?? ""
            );
            this.loading = false;
            window.location.href = "/dashboard";
          } else if (response.success == false) {
            this.errors = response.error;
            this.loading = false;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },
  },
};
</script>
