<template>
  <div>
    <h2 class="mt-5 text-center">Privacy Policy</h2>

    <div class="mt-3">
      1. Introduction This Privacy Policy explains how [University Name]
      collects, uses, shares, and protects personal information provided by
      students through the Student Portal. By using the Student Portal, you
      agree to the collection and use of your information in accordance with
      this policy. 2. Information We Collect Personal Information: When you
      register or access the Student Portal, we may collect personal
      information, including but not limited to your name, student ID, email
      address, contact details, and academic records. Usage Data: We may also
      collect information on how you access and use the Student Portal,
      including your IP address, browser type, pages visited, and the time and
      date of access. Cookies: The Student Portal may use cookies to enhance
      your user experience. Cookies are small files stored on your device that
      track information about your interactions with the portal. 3. How We Use
      Your Information Academic and Administrative Purposes: Your personal
      information is used to manage your academic records, facilitate course
      registration, access learning materials, communicate with faculty, and
      support other educational and administrative functions. Personalization:
      We may use your information to personalize your experience on the Student
      Portal, such as recommending courses or providing targeted content.
      Communication: We may use your contact information to send you important
      updates, notifications, and other communications related to your academic
      progress or the Student Portal. Security: Usage data is collected to
      monitor and improve the security and functionality of the Student Portal.
      4. Information Sharing and Disclosure Internal Use: Your information may
      be shared with authorized university staff and faculty who require access
      to perform their duties. Third-Party Service Providers: We may share your
      information with third-party service providers who assist in operating the
      Student Portal, conducting surveys, or providing other services on our
      behalf. These providers are bound by confidentiality agreements and are
      prohibited from using your information for any other purpose. Legal
      Compliance: We may disclose your information if required by law or in
      response to valid legal requests, such as a court order or subpoena. 5.
      Data Security We implement appropriate technical and organizational
      measures to protect your personal information from unauthorized access,
      alteration, disclosure, or destruction. This includes encryption, secure
      servers, and access controls. While we strive to protect your information,
      please be aware that no method of transmission over the internet or
      electronic storage is completely secure. 6. Your Rights Access and
      Correction: You have the right to access and correct your personal
      information stored on the Student Portal. You can update your information
      directly through the portal or contact the university’s IT department for
      assistance. Data Portability: You may request a copy of your personal
      information in a structured, commonly used, and machine-readable format.
      Right to Withdraw Consent: You can withdraw your consent for data
      processing at any time by contacting the university, though this may
      affect your ability to use certain features of the Student Portal. 7. Data
      Retention Your personal information will be retained for as long as
      necessary to fulfill the purposes outlined in this Privacy Policy,
      including compliance with legal obligations, resolving disputes, and
      enforcing agreements. 8. Children’s Privacy The Student Portal is intended
      for use by university students. We do not knowingly collect personal
      information from children under the age of 13. If we become aware that a
      child under 13 has provided us with personal information, we will take
      steps to delete such information. 9. Changes to This Privacy Policy We may
      update this Privacy Policy from time to time. Any changes will be posted
      on the Student Portal, and we will notify you of any significant changes.
      Your continued use of the portal after such changes constitutes acceptance
      of the new policy. 10. Contact Us If you have any questions or concerns
      about this Privacy Policy or the handling of your personal information,
      please contact us at [University Contact Information]. By using the
      Student Portal, you acknowledge that you have read and understood this
      Privacy Policy and agree to the collection and use of your personal
      information as described.
    </div>
  </div>
</template>