import axiosInstance from "@/helpers/axios";

export default {
  namespaced: true,
  actions: {
    getAll({ dispatch, commit },query) {
      return new Promise((resolve, reject) => {
        const queryString = query ? `?${Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&')}` : '';
        axiosInstance
          .get(`/show-services-requests${queryString}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((e) => {
            reject(e.response.data);
          });
      });
    },
    getSingle({ dispatch, commit }, { id }) {
        return new Promise((resolve, reject) => {
          axiosInstance
            .get(`/show-services-requests/${id}`)
            .then((response) => {
              resolve(response.data);
            })
            .catch((e) => {
              reject(e.response.data);
            });
        });
      },
  },
};
