<template>
  <div class="container">
    <loading
      :active="loading"
      :is-full-page="true"
      loader="bars"
      style="z-index: 5000"
    ></loading>
    <div class="row">
      <div class="col-md-12 mt-3">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center bg-white p-3 rounded shadow-sm"
        >
          <h2 class="h4 fw-bold text-dark mb-0">Tasks</h2>
          <button
            v-if="role == 'super-admin'"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#createTaskModal"
          >
            Create New Task
          </button>
        </div>

        <!-- Task List -->
        <div class="row g-4 mt-2">
          <div v-for="task in tasks" :key="task.id" class="col-md-6 col-lg-4">
            <router-link
              :to="`/task/${task.encrypted_id}`"
              class="text-decoration-none"
            >
              <div class="card h-100 border-0 shadow-sm">
                <div class="card-header bg-white">
                  <h5
                    class="card-title mb-0 text-truncate"
                    style="max-width: 100%"
                    :title="task.title"
                  >
                    {{ task.title }}
                  </h5>
                </div>
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <!-- Status on the left side -->
                    <span
                      class="badge mb-2"
                      :class="getStatusClass(task.status)"
                    >
                      {{ task.status }}
                    </span>

                    <!-- Due Date on the right side -->
                    <span class="text-sm" v-if="task.due_date">
                      Due: {{ formatDate(task?.due_date) }}
                    </span>
                  </div>
                  <p class="text-muted mb-0">
                    Assignees:
                    <span v-if="task.assignee_names">
                      {{ task.assignee_names }}
                    </span>
                    <span v-else> No TMA assigned yet </span>
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="createTaskModal"
      tabindex="-1"
      aria-labelledby="createTaskModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="createTaskModalLabel">
              Create New Task
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="createTask">
              <!-- Task Title -->
              <div class="mb-3">
                <label for="taskTitle" class="form-label">Task Title</label>
                <input
                  type="text"
                  v-model="newTask.title"
                  class="form-control"
                  id="taskTitle"
                  placeholder="Enter task title"
                  required
                />
              </div>

              <!-- Task Description -->
              <div class="mb-3">
                <label for="taskDescription" class="form-label"
                  >Task Description</label
                >
                <textarea
                  v-model="newTask.description"
                  class="form-control"
                  id="taskDescription"
                  rows="4"
                  placeholder="Enter task description"
                ></textarea>
              </div>

              <div class="mb-3">
                <label for="dueDate" class="form-label">Due Date</label>
                <input
                  v-model="newTask.due_date"
                  type="date"
                  class="form-control"
                  id="dueDate"
                  required
                />
              </div>

              <!-- Task Status -->
              <div class="mb-3">
                <label for="taskStatus" class="form-label">Status</label>
                <select
                  v-model="newTask.status"
                  class="form-select"
                  id="taskStatus"
                  required
                >
                  <option value="" disabled>Select status</option>
                  <option value="Created">Created</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>

              <!-- Assignees -->
              <div class="mb-3">
                <div class="d-flex flex-row align-items-center mb-2">
                  <div>
                    <label class="me-3 mb-0">Select TMA's</label>
                  </div>
                  <div class="d-flex align-items-center">
                    <input
                      type="checkbox"
                      :checked="newTask.assignees?.length == tmas.length"
                      style="margin: 0"
                      @change="toggleSelectAll"
                      id="selectAll"
                      class="form-check-input me-2"
                    />
                    <label for="selectAll" class="mb-0">
                      {{
                        newTask.tmas && newTask.assignees.length === tmas.length
                          ? "Unselect All"
                          : "Select All"
                      }}
                    </label>
                  </div>
                </div>
                <multiselect
                  class="mb-3"
                  v-model="newTask.assignees"
                  :options="tmas"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select TMA's"
                  :custom-label="tmaFormatedName"
                  track-by="id"
                >
                </multiselect>
              </div>

              <div class="card-body">
                <h5>Upload Files</h5>
                <div class="my-3">
                  <label
                    for="file-upload"
                    class="d-flex flex-column align-items-center justify-content-center border border-secondary rounded p-3 text-secondary"
                    style="height: 150px; cursor: pointer"
                  >
                    <UploadCloudIcon class="fs-1" />
                    <span>Click to upload or drag and drop</span>
                    <small>PNG, JPG, GIF, PDF, DOCX up to 10MB</small>
                    <input
                      id="file-upload"
                      type="file"
                      class="d-none"
                      @change="handleFileUpload"
                      multiple
                      accept=".png,.jpg,.jpeg,.gif,.pdf,.docx"
                    />
                  </label>
                </div>
                <ul class="list-group mb-3">
                  <li
                    v-for="(file, index) in newTask.files"
                    :key="index"
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <!-- File name and preview -->
                    <div class="d-flex align-items-center" style="width: 85%">
                      <template v-if="isImage(file)">
                        <img
                          :src="file.preview"
                          :alt="file.name"
                          class="img-thumbnail me-3"
                          style="width: 50px; height: 50px"
                        />
                        <span
                          class="text-truncate"
                          style="max-width: calc(100% - 60px)"
                        >
                          {{ file.name }}
                        </span>
                      </template>
                      <template v-else>
                        <a
                          :href="file.preview"
                          target="_blank"
                          rel="noopener noreferrer"
                          class="text-primary text-decoration-none text-truncate"
                          style="max-width: 100%"
                        >
                          {{ file.name }}
                        </a>
                      </template>
                    </div>

                    <!-- Remove button -->
                    <div style="width: 50%; text-align: right">
                      <button
                        @click="removeFile($event, index)"
                        class="btn btn-link text-danger p-0"
                      >
                        <XIcon />
                      </button>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Submit Button -->
              <button  :disabled="isFileUpload" type="submit" class="btn btn-primary w-100">
                {{ isFileUpload ? "Files Uploading..." : "Create Task" }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Multiselect from "vue-multiselect";
import helpers from "@/helpers/helpers";
import {
  FileIcon,
  ExternalLinkIcon,
  UploadCloudIcon,
  XIcon,
  PaperClipIcon,
} from "lucide-vue-next";
export default {
  components: {
    Multiselect,
    XIcon,
  },
  mixins: [helpers],
  data() {
    return {
      tasks: [],
      role:localStorage.getItem("role"),
      newTask: {
        title: "",
        description: "",
        status: "Created",
        assignees: [],
        files: [],
      },
      uploadProgress: {}, // Correctly initialize as an object
      tmas: [],
      loading: false,
      isFileUpload:false,
    };
  },
  mounted() {
    this.fetch();
    this.fetchTmas();
  },
  methods: {
    fetch() {
      console.log("fetch");
      this.loading = true;
      this.$store
        .dispatch("task/getAll", {})
        .then((response) => {
          this.tasks = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    removeFile(event, index) {
      event.preventDefault();
      this.newTask.files.splice(index, 1);
    },
    handleFileUpload(event) {
      this.isFileUpload = true;
      const files = Array.from(event.target.files);
      files.forEach((file) => {
        const preview = URL.createObjectURL(file);
        this.newTask.files.push({
          name: file.name,
          type: file.type,
          preview: preview,
          file: file,
        });
      });

      this.handleFileUploadSubmission();
    },
    async handleFileUploadSubmission() {
      for (let file of this.newTask.files) {
        try {
          const filePath = await this.uploadFileInChunksInGallary(
            file.file,
            2,
            3,
            "Home",
            (progress) => {
              this.uploadProgress[file.name] = progress;
            }
          );

          // Check if upload was successful and update file with the file path
          if (filePath) {
            file.uploadedPath = filePath;
          } else {
            this.$toast.show(`Failed to upload ${file.name}`, {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
          }
        } catch (error) {
          console.error("File upload error:", error);
          this.$toast.show(`Error uploading ${file.name}`, {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 5000,
          });
        }
      }
      this.isFileUpload = false;
    },
    isImage(type) {
      return type && typeof type === "string" && type.startsWith("image/");
    },
    getStatusClass(status) {
      switch (status) {
        case "In Progress":
          return "bg-warning text-dark";
        case "Created":
          return "bg-secondary";
        case "Completed":
          return "bg-success";
        default:
          return "bg-primary";
      }
    },
    getUploadProgress(file) {
      return this.uploadProgress[file.name] || 0;
    },
    simulateUpload(fileName) {
      const interval = setInterval(() => {
        if (this.uploadProgress[fileName] < 100) {
          this.uploadProgress[fileName] += 10; // Simulate 10% increments
        } else {
          clearInterval(interval); // Stop when upload completes
        }
      }, 500);
    },
    cancelUpload(index) {
      const file = this.newTask.files[index];
      delete this.uploadProgress[file.name]; // Remove progress tracking
      this.newTask.files.splice(index, 1); // Remove the file from the list
    },
    fetchTmas() {
      this.loading = true;
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("admins/getAllTmas", { query: { id } })
        .then((response) => {
          this.tmas = response.data;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    tmaFormatedName(tma) {
      return tma.name + " District:" + tma.district.name;
    },
    toggleSelectAll(event) {
      if (event.target.checked) {
        this.newTask.assignees = this.tmas;
      } else {
        this.newTask.assignees = [];
      }
    },
    createTask() {
      this.loading = true;
      const data = this.newTask;
      this.$store
        .dispatch("task/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.fetch();
            this.$toast.show("Successfuly Task Created", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.newTask).forEach(
              (key) => (this.newTask[key] = null)
            );
            this.newTask.status = "Created";
            this.newTask.assignees = [];
            this.newTask.files = [];
          } else {
            this.loading = false;
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    formatDate(date) {
      if (!date) return "";
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("en-US", options);
    },
  },
};
</script>
  
  <style scoped>
.card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.2s ease-in-out;
}

.card .card-header {
  padding: 12px !important;
}

.card .card-body {
  padding: 10px !important;
}
</style>
  