<template>
  <div class="container">
    <loading
      :active="loading"
      :is-full-page="true"
      loader="bars"
      style="z-index: 5000"
    ></loading>
  <div class="container-fluid bg-light py-2" v-if="task">
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow">
          <!-- Task Header -->
          <div class="card-header  text-white" style="padding: 15px; background-color: rgb(78, 1, 137);">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <p class="fw-bold mb-1">{{ task?.title }}</p>
                <div class="d-flex align-items-center gap-2">
                  <span
                    class="badge bg-danger text-white"
                    style="font-size: 0.8rem; padding: 0.5rem 1rem"
                    >{{ task?.status }}</span
                  >
                  <span v-if="task.due_date" class="text-sm"> Due: {{ formatDueDate(task?.due_date) }}</span>
                </div>
              </div>
              <button
                type="button"
                class="btn  d-flex align-items-center gap-2"
                style="background-color: #f4f4f4;"
                data-bs-toggle="modal"
                data-bs-target="#createTaskModal"
              >
                <i class="bi bi-pencil-square"></i> Update Task
              </button>
            </div>
          </div>

          <div class="card-body">
            <!-- Task Description -->
            <h5 class="card-title">Description</h5>
            <p class="card-text">{{ task?.description }}</p>

            <!-- Assignees -->
            <h5 class="card-title mt-4">Assignees</h5>
            <div class="d-flex align-items-center mb-3">
              <div v-if="task.assignees.length === 0" class="col-12">
    <p>No TMA assigned </p>
  </div>
              <div>
                {{
                  task.assignees
                    .map(
                      (assignee) =>
                        `${assignee.name}-District:${assignee.district.name}`
                    )
                    .join(", ")
                }}
              </div>
            </div>

            <!-- File Attachments -->
            <h5 class="card-title mt-4">Attached Files</h5>
            <div class="row g-3">
              <div v-if="task.files.length === 0" class="col-12">
                <p>No files attached</p>
              </div>
              <div v-for="file in task.files" :key="file.id" class="col-md-6">
                <div class="card">
                  <div
                    class="card-body d-flex align-items-center"
                    style="
                      max-width: 100%;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    <!-- Show preview for images -->
                    <div
                      class="d-flex align-items-center"
                      v-if="isImage(file.type)"
                    >
                      <img
                        :src="file.image_url"
                        alt="File preview"
                        class="me-3"
                        style="width: 50px; height: 50px; object-fit: cover"
                      />
                      <a
                        :href="file.image_url"
                        target="_blank"
                        class="text-decoration-none"
                      >
                        <h6 class="card-title mb-0">
                          {{ getFileName(file.image_url) }}
                        </h6>
                      </a>
                    </div>

                    <!-- Show icons for non-image files -->
                    <div v-else>
                      <component
                        :is="fileIcon(file.type)"
                        class="me-3 fs-4 text-primary"
                      />

                      <div>
                        <a
                          :href="file.image_url"
                          target="_blank"
                          class="text-decoration-none"
                        >
                          <h6 class="card-title mb-0">
                            {{ getFileName(file.image_url) }}
                          </h6>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Comments -->
            <h5 class="card-title mt-4">Comments</h5>
            <div
              class="mb-4"
              style="max-height: 400px; overflow-y: auto"
              ref="commentsContainer"
            >
            <div v-if="task.comments.length === 0" class="col-12">
              <p>No comments yet</p>
  </div>
              <div
                v-for="comment in task.comments"
                :key="comment.id"
                class="card mb-3"
              >
                <div class="card-body">
                  <div class="d-flex">
                    <!-- Avatar with fallback to first letter -->
                    <div
                      class="rounded-circle me-3 d-flex align-items-center justify-content-center"
                      style="
                        width: 40px;
                        height: 40px;
                        background-color: #007bff;
                        color: white;
                        font-size: 18px;
                      "
                    >
                      {{ comment.user.f_name.charAt(0) }}
                    </div>

                    <div class="flex-grow-1">
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <h6 class="mb-0">{{ comment.user.f_name }}</h6>
                        <small class="text-muted">{{
                          formatDate(comment.created_at)
                        }}</small>
                      </div>
                      <p class="mb-1">{{ comment.comment }}</p>
                      <div
                        v-if="comment.files.length > 0"
                        class="d-flex flex-wrap gap-2"
                      >
                        <span
                          v-for="file in comment.files"
                          :key="file.id"
                          class="badge bg-light text-dark"
                        >
                          <component :is="PaperclipIcon" class="me-1" />
                          <a
                            :href="file.image_url"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-decoration-none text-dark"
                          >
                            <span v-if="file.newComment">{{
                              getFileNameWithExtension(file)
                            }}</span>
                            <span v-else>{{
                              getFileName(file.image_url)
                            }}</span>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Add Comment Form -->
            <form @submit.prevent="addComment">
              <div class="mb-3">
                <label for="newComment" class="form-label">Add a comment</label>
                <textarea
                  v-model="newComment"
                  class="form-control"
                  id="newComment"
                  rows="3"
                ></textarea>
              </div>

              <div
                v-if="selectedFiles.length > 0"
                class="d-flex flex-wrap gap-2"
              >
                <span
                  v-for="(file, index) in selectedFiles"
                  :key="file.id"
                  class="badge bg-light text-dark position-relative"
                  style="padding-right: 2rem; padding-top: 1rem"
                >
                  <component :is="PaperclipIcon" class="me-1" />
                  <a
                    :href="file.preview"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-decoration-none text-dark"
                  >
                    <span>{{ file.name }}</span>
                  </a>
                  <button
                    type="button"
                    class="btn-close position-absolute top-0 end-0 me-1 mt-1"
                    aria-label="Remove"
                    @click="removeFileUpload($event, index)"
                    style="font-size: 0.75rem"
                  ></button>
                </span>
              </div>

              <div
                class="d-flex justify-content-between align-items-center mt-2"
              >
                <div>
                  <label for="fileUpload" class="btn btn-outline-primary me-2">
                    <component :is="PaperclipIcon" class="me-1" /> Attach files
                  </label>
                  <input
                    type="file"
                    id="fileUpload"
                    multiple
                    class="d-none"
                    ref="fileInput"
                    @change="handleFileUpload"
                    accept=".pdf, .doc, .docx, image/*"
                  />
                  <span v-if="selectedFiles.length > 0" class="text-muted"
                    >{{ selectedFiles.length }} file(s) selected</span
                  >
                </div>
                <button
                  :disabled="isFileUpload"
                  type="submit"
                  class="btn btn-primary"
                >
                  {{ isFileUpload ? "Files Uploading..." : "Post Comment" }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="createTaskModal"
      tabindex="-1"
      aria-labelledby="createTaskModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-dark" id="createTaskModalLabel">
              Update Task
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body" style="color: #212529">
            <form @submit.prevent="updateTask">
              <div class="mb-3" v-if="role == 'super-admin'">
                <label for="taskTitle" class="form-label">Task Title</label>
                <input
                  type="text"
                  v-model="task.title"
                  class="form-control"
                  id="taskTitle"
                  placeholder="Enter task title"
                  required
                />
              </div>
              <div class="mb-3" v-if="role == 'super-admin'">
                <label for="taskDescription" class="form-label"
                  >Task Description</label
                >
                <textarea
                  v-model="task.description"
                  class="form-control"
                  id="taskDescription"
                  rows="4"
                  placeholder="Enter task description"
                ></textarea>
              </div>

              <div class="mb-3" v-if="role == 'super-admin'">
                <label for="dueDate" class="form-label">Due Date</label>
                <input
                  v-model="task.due_date"
                  type="date"
                  class="form-control"
                  id="dueDate"
                  required
                />
              </div>

              <div class="mb-3">
                <label for="taskStatus" class="form-label">Status</label>
                <select
                  v-model="task.status"
                  class="form-select"
                  id="taskStatus"
                  required
                >
                  <option value="" disabled>Select status</option>
                  <option value="Created" >Created</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>

              <!-- Assignees -->
              <div class="mb-3" v-if="role == 'super-admin'">
                <div class="d-flex flex-row align-items-center mb-2">
                  <div>
                    <label class="me-3 mb-0">Select TMA's</label>
                  </div>
                  <div class="d-flex align-items-center">
                    <input
                      type="checkbox"
                      :checked="task.assignees?.length == tmas.length"
                      style="margin: 0"
                      @change="toggleSelectAll"
                      id="selectAll"
                      class="form-check-input me-2"
                    />
                    <label for="selectAll" class="mb-0">
                      {{
                        task.tmas && task.assignees.length === tmas.length
                          ? "Unselect All"
                          : "Select All"
                      }}
                    </label>
                  </div>
                </div>
                <multiselect
                  class="mb-3"
                  v-model="task.assignees"
                  :options="tmas"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select TMA's"
                  :custom-label="tmaFormatedName"
                  track-by="id"
                >
                </multiselect>
              </div>

              <button type="submit" class="btn btn-primary w-100">
                Update Task
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>


<script>
import { Clock, FileText, FileVideo, File } from "lucide-vue-next";
import helpers from "@/helpers/helpers";

export default {
  components: {
    ClockIcon: Clock,
    FileText,
    FileVideo,
    File,
  },
  mixins: [helpers],
  data() {
    return {
      id: null,
      task: null,
      role:localStorage.getItem("role"),
      isFileUpload: false,
      loading: false,
      tmas: [],
      newComment: "",
      selectedFiles: [],
    };
  },
  computed: {
    statusBadgeClass() {
      switch (this.task.status.toLowerCase()) {
        case "In Progress":
          return "bg-warning";
        case "Completed":
          return "bg-success";
        default:
          return "bg-secondary";
      }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.loading = true;
    this.fetchTmas();
    this.fetch();
   
  },
  methods: {
    fetch() {
      const id = this.id;
      this.$store
        .dispatch("task/getSingle", { id })
        .then((response) => {
          console.log("Task data fetched:", response.data);
          this.task = response.data;
          this.$nextTick(() => {
        this.loading = false;
      });
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    fetchTmas() {
      const id = localStorage.getItem("uid");
      this.$store
        .dispatch("admins/getAllTmas", { query: { id } })
        .then((response) => {
          this.tmas = response.data;
        })
        .catch((e) => {
          console.log(e.error);
          this.loading = false;
        });
    },
    isImage(type) {
      return ["image", "jpeg", "png", "gif", "bmp"].includes(
        type.toLowerCase()
      );
    },

    getFileName(url) {
      return url.split("/").pop();
    },

    fileIcon(type) {
      switch (type.toLowerCase()) {
        case "pdf":
        case "docx":
        case "doc":
          return FileText;
        case "video":
          return FileVideo;
        default:
          return File;
      }
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.commentsContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },

    async handleFileUploadSubmission() {
      for (let file of this.selectedFiles) {
        try {
          const filePath = await this.uploadFileInChunksInGallary(
            file.file,
            2,
            3,
            "Home",
            (progress) => {}
          );

          if (filePath) {
            file.uploadedPath = filePath;
          }
        } catch (error) {
          console.error("File upload error:", error);
        }
      }
      this.isFileUpload = false;
    },
    handleFileUpload(event) {
      this.isFileUpload = true;
      const files = Array.from(event.target.files);
      files.forEach((file) => {
        const preview = URL.createObjectURL(file);
        this.selectedFiles.push({
          name: file.name,
          type: file.type,
          preview: preview,
          file: file,
        });
      });

      this.handleFileUploadSubmission();
    },
    getFileNameWithExtension(file) {
      const mimeToExtension = {
        "application/pdf": "pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          "docx",
        "application/msword": "doc",
        "image/png": "png",
        "image/jpeg": "jpeg",
        "image/jpg": "jpg",
      };

      const extension = mimeToExtension[file.type] || "";
      if (file.name.endsWith(`.${extension}`)) {
        return file.name;
      }
      return `${file.name}.${extension}`;
    },
    addComment() {
      if (this.newComment.trim() === "" && this.selectedFiles.length === 0)
        return;

      const data = {
        comment: this.newComment,
        task_id: this.id,
        user: {
          f_name: localStorage.getItem("f_name"),
        },
        created_at: new Date().toISOString().slice(0, 19).replace("T", " "),
        files: this.selectedFiles.map((file) => ({
          name: file.name,
          path: file.uploadedPath,
          type: file.type,
          image_url: file.preview,
          newComment: true,
        })),
      };

      this.task.comments.push(data);
      this.scrollToBottom();

      this.$toast.show("Successfuly Comment Added", {
        theme: "outline",
        position: "top",
        type: "success",
        duration: 5000,
      });

      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }

      this.$store
        .dispatch("task/storeComment", { data })
        .then((response) => {})
        .catch((e) => {
          this.loading = false;
        });

      this.newComment = "";
      this.selectedFiles = [];
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleString();
    },

    removeFileUpload(event, index) {
      event.preventDefault();
      this.selectedFiles.splice(index, 1);
    },

    updateTask() {
      this.loading = true;
      const data = this.task;
      const id = this.id;
      this.$store
        .dispatch("task/update", { data, id })
        .then((response) => {
          this.loading = false;
          this.$toast.show("Successfuly Updated", {
            theme: "outline",
            position: "top",
            type: "success",
            duration: 5000,
          });
        })
        .catch((e) => {
          this.loading = false;
        });
    },

    tmaFormatedName(tma) {
      return tma.name + " District:" + tma.district.name;
    },

    toggleSelectAll(event) {
      if (event.target.checked) {
        this.task.assignees = this.tmas;
      } else {
        this.task.assignees = [];
      }
    },

    formatDueDate(date) {
      if (!date) return ''; 
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(date).toLocaleDateString('en-US', options);
    }
  },
};
</script>

