<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-6 mt-5 mx-auto">
        <router-link to="/campaigns" class="btn btn-sm btn-primary mb-3"
          >Back</router-link
        >
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Add Campaign</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addRole">
              <label>Title <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control form-control-sm mb-3"
                required
                v-model="campaign.title"
              />
              <label>Campaign Type <span class="text-danger">*</span></label>
              <select
                class="custom-select mb-3"
                required
                v-model="campaign.campaign_type"
              >
                <option value="" selected>Select Campaign Type</option>
                <option v-for="t in types" :value="t.id" :key="t.id">
                  {{ t.name }}
                </option>
              </select>
              <label>Start Date <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control form-control-sm mb-3"
                required
                v-model="campaign.start_date"
              />
              <label>End Date <span class="text-danger">*</span></label>
              <input
                type="date"
                class="form-control form-control-sm mb-3"
                required
                v-model="campaign.end_date"
              />
              <label
                >Upload Cover Image
                <span class="text-danger">*</span></label
              >
              <br />
              <input
                ref="video"
                type="file"
                accept="image/*"
                class="form-control"
                @change="handleFileUpload"
                required
              />
              <img
                v-if="mediaType === 'image'"
                :src="imagePreview"
                alt="Image Preview"
                class="mt-2 w-100 mb-2"
              />
              <video
                class="mt-2 mb-2 w-100"
                id="video-preview"
                controls
                :src="imagePreview"
                v-if="mediaType == 'video'"
              />
              <div class="row">
                <div class="col-md-12">
                  <div v-if="uploadProgress != -1" class="progress-bar">
                    <div
                      class="progress"
                      :style="{
                        width: uploadProgress + '%',
                        paddingLeft: Math.min(uploadProgress / 2, 50) + '%',
                      }"
                    >
                      {{ uploadProgress }}%
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center mt-4">
                <div class="d-flex align-items-center">
                  <label class="me-3">Request Volunteers</label>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="registervolunteer"
                      v-model="campaign.register_volunteers"
                      :true-value="1"
                      :false-value="0"
                    />
                  </div>
                </div>
                <div class="d-flex align-items-center ms-4">
                  <label class="me-3">Send Notification</label>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="sendNotificationYes"
                      @change="handleNotificationChange"
                      v-model="campaign.notification"
                      :true-value="1"
                      :false-value="0"
                    />
                  </div>
                </div>
              </div>
              <label class="mt-2">Description</label>
              <QuillEditor
                v-model="campaign.detail"
                :editorHeight="'300px'"
                :editorWidth="'800px'"
              />
              <div class="row mt-3">
                <div class="col-md-12">
                  <button type="submit" :disabled="uploadProgress > 0" class="btn btn-sm btn-primary">
                    {{ uploadProgress > 0  ? 'Please wait, file uploading...' : 'Save' }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <button
      type="button"
      id="openModel"
      class="d-none"
      data-bs-toggle="modal"
      data-bs-target="#notificationModal"
    >
      Open Modal
    </button>

    <notification_filter @save="filterResult"></notification_filter>
  </div>
</template>

<script>
import { toRaw } from "vue";
import QuillEditor from "@/components/QuillEditor.vue";
import notification_filter from "@/components/notification_filter.vue";
import helpers from "@/helpers/helpers";

export default {
  components: {
    QuillEditor,
    notification_filter,
  },
  mixins: [helpers],
  data() {
    return {
      campaign: {
        register_volunteers: 0,
        image: null,
        notification: 0,
        campaign_type: "",
        title: "",
        detail: "",
        start_date: "",
        end_date: "",
        notificationFilter: [],
      },
      mediaType: "",
      imagePreview: null,
      loading: false,
      modules: [],
      types: [],
      uploadProgress: -1,
    };
  },
  mounted() {
    this.fetchModules();
    this.fetchCompaignsTypes();
  },
  methods: {
    async handleFileUpload(event) {
      const target = event.target;
      const file = target.files?.[0];
      if (file) {
        this.mediaType = file.type.startsWith("image")
          ? "image"
          : file.type.startsWith("video")
          ? "video"
          : null;
        this.imagePreview = URL.createObjectURL(file);
        this.uploadProgress = 0.01;
        let filePath = await this.uploadFileInChunks(
          file,
          2,
          3,
          "Campaigns",
          (percentage) => {
            this.uploadProgress = percentage;
          }
        );
        this.campaign.image = filePath;
        this.uploadProgress = -1;
      }
    },
    submit() {
      this.loading = true;
      const data = toRaw(this.campaign);
      this.$store
        .dispatch("campaigns/store", { data })
        .then((response) => {
          this.loading = false;
          if (response.success === true) {
            this.$toast.show("Successfuly added", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            Object.keys(this.campaign).forEach(
              (key) => (this.campaign[key] = null)
            );
            this.$refs.video.value = "";
            this.campaign.register_volunteers = 0;
            this.campaign.notification = 0;
            this.campaign.campaign_type = "";
            this.mediaType = "";
            this.uploadProgress=-1;
          } else {
            this.$toast.show("Failed to save", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 5000,
            });
            this.uploadProgress=-1;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.show("Failed to save", {
            theme: "outline",
            position: "top",
            type: "error",
            duration: 10000,
          });
          this.uploadProgress=-1;
        });
    },
    fetchModules() {
      this.loading = true;
      this.$store
        .dispatch("module/getAll")
        .then((response) => {
          this.loading = false;
          this.modules = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    fetchCompaignsTypes() {
      this.loading = true;
      this.$store
        .dispatch("campaigntypes/getAll")
        .then((response) => {
          this.loading = false;

          this.types = response.data;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    handleNotificationChange() {
      if (this.campaign.notification == 1) {
        const btn = document.getElementById("openModel");
        btn.click();
      }
    },
    filterResult(result) {
      this.campaign.notificationFilter = result;
    },
  },
};
</script>

<style>
.dropdown-icon {
  width: 15px;
  height: 15px;
}

.custom-select {
  width: 100%;
  padding: 6px;
  font-size: 14px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}
</style>